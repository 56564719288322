body {
    background-color: #eeeff0;
}

// Overriding Okta styles
#sign-in-widget {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
#okta-sign-in {
    &.auth-container {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    
    &.auth-container.main-container {
        margin: 0!important;
        border-style: none!important;
    }
    
    &.auth-container .auth-content{        
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 6.25rem;
    }

    .okta-sign-in-header.auth-header {
        display: none;
    }

    .okta-form-title.o-form-head {
        font-size: 1.125rem;
        margin-bottom: 1.25rem;
        text-align: left;
    }
}

// Overriding Tableau styles
#insights-tableau-id {
    justify-content: space-around;
}

#download-dropdown {
    background-color: transparent!important; 
    color: black;
 }

 .col-small {
    width: 50px;
 }

 .show-more--button {
    color: #336eb4;
    text-decoration: none;
    background-color: transparent;
}

.modal {
    z-index: 1049;
}


@media (min-width: 768px) {
    .pioneer-modal-lg {
      max-width: 700px;
    }
    .pioneer-modal-xl,
    .pioneer-modal-xxl {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .pioneer-modal-lg {
      max-width: 900px;
    }
    .pioneer-modal-xl,
    .pioneer-modal-xxl
     {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .pioneer-modal-lg {
      max-width: 1000px;
    }
    .pioneer-modal-xl,
    .pioneer-modal-xxl
     {
      max-width: 1200px;
    }    

  }

  @media (min-width: 1300px) {
    .pioneer-modal-xxl {
      max-width: 1300px;
    }    

  }